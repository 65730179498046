import React, { useContext, useEffect } from "react";

import TextRectangle from "../elements/TextRectangle";
import { SquareDataContext } from "../contexts/SquareData";
import { LayerDataContext } from "../contexts/LayerData";

const RectangleManager = () => {
  const { squareData, setSquareData } = useContext(SquareDataContext);
  const { currentPathIndex, currentPath } = useContext(LayerDataContext);

  useEffect(() => {
    const savedSquares = localStorage.getItem("squareDataSave");
    if (savedSquares) {
      setSquareData(JSON.parse(savedSquares));
    }
  }, []);

  const saveNewDimensions = (idToSearch, newData) => {
    const newSquareData = squareData.map((x) => {
      if (x.id === idToSearch) {
        if (newData.width !== undefined) {
          return { ...newData, layer: x.layer };
        }
        return { ...newData, width: x.width, height: x.height, layer: x.layer };
      }
      return x;
    });
    setSquareData(newSquareData);
  };

  return (
    <div className="square-container">
      {squareData
        .filter((sq) => sq.layer === currentPath[currentPathIndex].layer)
        .map((sdat) => (
          <TextRectangle
            key={sdat.id}
            data={sdat}
            saveNewDimensions={saveNewDimensions}
          />
        ))}
    </div>
  );
};

export default RectangleManager;
