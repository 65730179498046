import React, { useState } from "react";
import { Rnd } from "react-rnd";
import { Divider, Typography } from "antd";

import DeleteElement from "../components/DeleteElement";

const { Title } = Typography;

const TextRectangle = (props) => {
  const { data, saveNewDimensions } = props;

  const [width, setWidth] = useState(data.width);
  const [height, setHeight] = useState(data.height);
  const [x, setX] = useState(data.x);
  const [y, setY] = useState(data.y);
  const [isEditing, setIsEditing] = useState(false);
  const [text, setText] = useState(data.text || "--");
  const [title, setTitle] = useState(data.title || "--");

  const handleDoubleClick = () => {
    setIsEditing(true);
  };

  const handleBlur = () => {
    setIsEditing(false);
  };

  const handleChange = (e) => {
    setText(e.target.value);
    saveNewDimensions(data.id, {
      id: data.id,
      text: e.target.value,
      x,
      y,
      title,
    });
  };

  const handleChangeTitle = (val) => {
    setTitle(val);
    saveNewDimensions(data.id, { id: data.id, text, x, y, title: val });
  };

  const handleKeyDown = (e) => {
    if (e.key === "Escape") {
      setIsEditing(false);
    }
  };

  return (
    <DeleteElement id={data.id}>
      <Rnd
        style={{
          padding: "10px",
          border: "solid 1px",
          background: "#efefef",
          wordWrap: "break-word",
          overflow: "hidden",
        }}
        size={{ width, height }}
        position={{ x, y }}
        disableDragging={isEditing}
        onDragStop={(e, d) => {
          setX(d.x);
          setY(d.y);
          saveNewDimensions(data.id, {
            id: data.id,
            text,
            x: d.x,
            y: d.y,
            title,
          });
        }}
        onResizeStop={(e, direction, ref, delta, position) => {
          setWidth(ref.style.width);
          setHeight(ref.style.height);
          setX(position.x);
          setY(position.y);
          saveNewDimensions(data.id, {
            id: data.id,
            text,
            x: position.x,
            y: position.y,
            width: ref.style.width,
            height: ref.style.height,
            title,
          });
        }}
        resizeGrid={[20, 20]}
        dragGrid={[20, 20]}
      >
        <Title
          editable={{
            onChange: handleChangeTitle,
            triggerType: ["text"],
          }}
          style={{
            margin: 0,
          }}
          level={3}
        >
          {title}
        </Title>
        <Divider />
        <div onDoubleClick={handleDoubleClick}>
          {isEditing ? (
            <textarea
              value={text}
              onChange={handleChange}
              onBlur={handleBlur}
              onKeyDown={handleKeyDown}
              rows={4}
              style={{
                width: "100%",
                height: `calc(${height} - 100px)`,
                resize: "none",
              }}
              autoFocus
            />
          ) : (
            <div>
              {text.split("\n").map((line, index) => (
                <div key={index}>{line}</div>
              ))}
            </div>
          )}
        </div>
      </Rnd>
    </DeleteElement>
  );
};

export default TextRectangle;
