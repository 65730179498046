import "./App.css";
import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import IndexNoteTool from "./notetool/IndexNoteTool";
import IndexNetCalendar from "./netcalendar/IndexNetCalendar.tsx";
import CalendarTable from "./netcalendar/components/CalendarTable.tsx";
import SchoolTimetable from "./netcalendar/components/SchoolTimetable.tsx";

const App = () => (
  <Router>
    <div className="App">
      <Routes>
        <Route path="/" element={<IndexNetCalendar />} />
        <Route path="/timetable" element={<SchoolTimetable />} />
        <Route path="/cal" element={<CalendarTable />} />
        <Route path="/lignanote" element={<IndexNoteTool />} />
      </Routes>
    </div>
  </Router>
);

export default App;
