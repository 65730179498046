import axios from "axios";
import React, { useState, useEffect } from "react";
import { Table, Modal, Input, Row, Select, Form, Col, Button } from "antd";
import { createStyles } from "antd-style";
import moment from "moment";
import type { Schema } from "../../../amplify/data/resource";
import { generateClient } from "aws-amplify/data";
import TimeInput from "./TimeInput.tsx";
import ColorPickerDropdown from "./ColorPickerDropdown.tsx";
import "./tableStyles.css";
import CalendarMenu from "./CalendarMenu.tsx";

const client = generateClient<Schema>();

const { Option } = Select;

const CalendarTable = () => {
  const [events, setEvents] = useState<Array<Schema["CalendarEvent"]["type"]>>(
    []
  );
  const [modalMode, setModalMode] = useState(0); //0 off, 1 selection, 2 new, 3 edit/delete
  const [isDayModalVisible, setIsDayModalVisible] = useState(false);
  const [selectedCell, setSelectedCell] = useState(null);
  const [activeEvents, setActiveEvents] = useState([]);
  const [editInitialValues, setEditInitialValues] = useState({});
  const [form] = Form.useForm();

  const today = moment().format("YYYY-MM-DD");
  const [currentMonth, setCurrentMonth] = useState(moment().month());
  const [currentYear, setCurrentYear] = useState(moment().year());
  const [holidays, setHolidays] = useState([]);
  const [schoolHolidays, setSchoolHolidays] = useState([]);

  const useStyle = createStyles(({ css, token }) => {
    const { antCls } = token;
    return {
      customTable: css`
        ${antCls}-table {
          ${antCls}-table-container {
            ${antCls}-table-body,
            ${antCls}-table-content {
              scrollbar-width: thin;
              scrollbar-color: unset;
            }
          }
        }
      `,
    };
  });
  const { styles } = useStyle();

  useEffect(() => {
    const fetchHolidays = async () => {
      try {
        // Feiertage-API: URL zum Abrufen der Feiertage für Bayern
        const response = await axios.get(
          `https://feiertage-api.de/api/?jahr=${moment().format(
            "YYYY"
          )}&nur_land=BY`
        );
        delete response.data["Augsburger Friedensfest"];
        const arrOnly = Object.values(response.data).map(
          (holiday) => holiday.datum
        );
        setHolidays(arrOnly); // Setze die Feiertage in den State
      } catch (err) {
        // TODO
      }
    };

    const fetchSchoolHolidays = async () => {
      try {
        const response = await fetch(
          `https://ferien-api.de/api/v1/holidays/BY/${moment().format("YYYY")}`
        );
        if (!response.ok)
          console.error(
            "Error fetching school holiday data. No response",
            response
          );
        const data = await response.json();
        setSchoolHolidays(data);
      } catch (error) {
        console.error("Error fetching school holiday data:", error);
      }
    };

    fetchHolidays();
    fetchSchoolHolidays();
  }, []);

  useEffect(() => {
    if (form && modalMode === 2) {
      const correctTime = {
        ...editInitialValues,
        time:
          editInitialValues?.time !== undefined
            ? editInitialValues?.time.length > 5
              ? moment(editInitialValues.time).format("HH:mm")
              : editInitialValues.time
            : "00:00",
        timeTo:
          editInitialValues?.timeTo !== undefined && !!editInitialValues?.timeTo
            ? editInitialValues?.timeTo.length > 5
              ? moment(editInitialValues?.timeTo).format("HH:mm")
              : editInitialValues?.timeTo
            : "00:00",
      };
      form.setFieldsValue({
        ...correctTime,
        description: editInitialValues.content,
        type: "event",
      });
    }
  }, [editInitialValues, modalMode]);

  useEffect(() => {
    client.models.CalendarEvent.observeQuery().subscribe({
      next: (data) => {
        setEvents([...data.items]);
      },
    });
  }, []);

  const dataSource = Array.from({ length: 62 }, (_, i) => {
    const day = moment()
      .year(currentYear)
      .month(currentMonth)
      .startOf("month")
      .add(i, "days");
    return {
      key: day.format("YYYY-MM-DD"),
      date: day.format("DD MMM"),
      julia:
        events.filter(
          (f) =>
            f.date === day.format("YYYY-MM-DD") && f.member?.includes("julia")
        ) || "",
      mark:
        events.filter(
          (f) =>
            f.date === day.format("YYYY-MM-DD") && f.member?.includes("mark")
        ) || "",
      noah:
        events.filter(
          (f) =>
            f.date === day.format("YYYY-MM-DD") && f.member?.includes("noah")
        ) || "",
      sonja:
        events.filter(
          (f) =>
            f.date === day.format("YYYY-MM-DD") && f.member?.includes("sonja")
        ) || "",
      henry:
        events.filter(
          (f) =>
            f.date === day.format("YYYY-MM-DD") && f.member?.includes("henry")
        ) || "",
      wholeday: events.filter(
        (f) =>
          f.date === day.format("YYYY-MM-DD") &&
          f.type?.toString() === "wholeday"
      ),
    };
  });

  const isWeekend = (recordKey) => {
    const dayOfWeek = moment(recordKey).day(); // 0 = Sonntag, 6 = Samstag
    return dayOfWeek === 0 || dayOfWeek === 6;
  };

  const columns = [
    {
      title: "Datum",
      dataIndex: "date",
      key: "date",
      onCell: (record) => onDayCellClick(record, ""),
      render: (text, record) => {
        return (
          <div>
            <div
              style={{
                backgroundColor:
                  record.key === today
                    ? "#CCBB00"
                    : isWeekend(record.key) || holidays.includes(record.key)
                    ? "#d0d0d0"
                    : "transparent",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <div>{text}</div>
              <div>{moment(record.key).format("dd")}</div>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "right",
                backgroundColor: "red",
                color: "white",
              }}
            >
              {record.wholeday.map((x, i) => (
                <div key={i}>
                  <br />
                  {x.content}
                </div>
              ))}
            </div>
          </div>
        );
      },
    },
    {
      title: "Julia",
      dataIndex: "julia",
      key: "julia",
      onCell: (record) => onCellClick(record, "julia"),
      render: (entries, record) =>
        renderCellEvent(onCellClick, record, entries, "julia"),
      className: "julia-column",
    },
    {
      title: "Mark",
      dataIndex: "mark",
      key: "mark",
      onCell: (record) => onCellClick(record, "mark"),
      render: (entries, record) =>
        renderCellEvent(onCellClick, record, entries, "mark"),
      className: "mark-column",
    },
    {
      title: "Noah",
      dataIndex: "noah",
      key: "noah",
      onCell: (record) => onCellClick(record, "noah"),
      render: (entries, record) =>
        renderCellEvent(onCellClick, record, entries, "noah", schoolHolidays),
      className: "noah-column",
    },
    {
      title: "Sonja",
      dataIndex: "sonja",
      key: "sonja",
      onCell: (record) => onCellClick(record, "sonja"),
      render: (entries, record) =>
        renderCellEvent(onCellClick, record, entries, "sonja"),
      className: "sonja-column",
    },
    {
      title: "Henry",
      dataIndex: "henry",
      key: "henry",
      onCell: (record) => onCellClick(record, "henry"),
      render: (entries, record) =>
        renderCellEvent(onCellClick, record, entries, "henry"),
      className: "henry-column",
    },
  ];

  const onCellClick = (record, member) => {
    return {
      onClick: () => {
        setSelectedCell({ date: record.key, member });
        setActiveEvents(
          events.filter(
            (f) => f.date === record.key && f.member?.toString() === member
          )
        );
        setModalMode(1);
        form.resetFields();
        form.setFieldsValue({ type: "event" });
        setEditInitialValues({});
      },
    };
  };

  const onDayCellClick = (record, member) => {
    return {
      onClick: () => {
        setSelectedCell({ date: record.key, member });
        setActiveEvents(
          events.filter((f) => f.date === record.key && f.type === "wholeday")
        );
        setIsDayModalVisible(true);
        form.resetFields();
        form.setFieldsValue({ type: "wholeday" });
      },
    };
  };

  const handleModalOk = () => {
    form
      .validateFields()
      .then((values) => {
        const saveValues = {
          date: selectedCell.date,
          time: !!values.time ? values.time : "00:00",
          timeTo: !!values.timeTo ? values.timeTo : "00:00",
          member: Array.isArray(selectedCell.member)
            ? selectedCell.member
            : [selectedCell.member],
          content: values.description,
          color: values.color,
          type: values.type,
        };
        if (values.id !== undefined) {
          client.models.CalendarEvent.update({ ...saveValues, id: values.id });
        } else {
          client.models.CalendarEvent.create(saveValues);
        }
        setEditInitialValues({});
        setModalMode(0);
        setIsDayModalVisible(false);
      })
      .catch((info) => {
        console.error("Validation failed:", info);
      });
  };

  return (
    <div>
      <div
        style={{
          marginBottom: 16,
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <CalendarMenu />
        <Select
          value={currentMonth}
          onChange={setCurrentMonth}
          style={{ width: 80 }}
        >
          {Array.from({ length: 12 }, (_, i) => (
            <Option key={i} value={i}>
              {moment().month(i).format("MMM")}
            </Option>
          ))}
        </Select>
        <Button onClick={() => setCurrentMonth((currentMonth + 11) % 12)}>
          {"<"}
        </Button>
        <Button onClick={() => setCurrentMonth((currentMonth + 1) % 12)}>
          {">"}
        </Button>
        <Select
          value={currentYear}
          onChange={setCurrentYear}
          style={{ width: 80 }}
        >
          {Array.from({ length: 5 }, (_, i) => (
            <Option key={currentYear - 2 + i} value={currentYear - 2 + i}>
              {currentYear - 2 + i}
            </Option>
          ))}
        </Select>
      </div>
      <Table
        className={styles.customTable}
        dataSource={dataSource}
        columns={columns}
        pagination={false}
        // scroll={{ x: true }}
        size="small"
        bordered={true}
        scroll={{
          y: 100 * 5,
        }}
      />

      {/* Hauptauswahl Modal  */}
      <Modal
        title="Aktionen"
        open={modalMode === 1}
        onCancel={() => setModalMode(0)}
        footer={[
          <Button key="cancel" onClick={() => setModalMode(0)}>
            Abbrechen
          </Button>,
        ]}
        width={600}
        styles={{ body: { padding: 0 } }}
      >
        <Row style={{ height: "200px" }}>
          <Col span={12}>
            <Button
              type="primary"
              block
              style={{ height: "100%", fontSize: "24px" }}
              onClick={() => setModalMode(3)}
              disabled={
                activeEvents.filter(
                  (f) => !f.type || f.type.toString() !== "wholeday"
                ).length === 0
              }
            >
              Bearbeiten
            </Button>
          </Col>
          <Col span={12}>
            <Button
              type="default"
              block
              style={{ height: "100%", fontSize: "24px" }}
              onClick={() => {
                setModalMode(2);
                setEditInitialValues({});
              }}
            >
              Neu
            </Button>
          </Col>
        </Row>
      </Modal>

      {/* Bearbeiten Modal */}
      <Modal
        title="Termin bearbeiten"
        open={modalMode === 3}
        onCancel={() => setModalMode(0)}
        onOk={handleModalOk}
      >
        <Row>
          {activeEvents
            .filter((f) => !f.type || f.type.toString() !== "wholeday")
            .map((x, i) => (
              <Col
                span={24}
                key={i}
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  borderBottom: "1px dotted #cccccc",
                }}
              >
                <div>
                  {showTime(x.time)}
                  {showTime(x.timeTo, true)} {x.content}
                </div>
                <div style={{ display: "flex", justifyContent: "right" }}>
                  <Button
                    onClick={() => {
                      setSelectedCell({
                        ...selectedCell,
                        date: x.date,
                        member: x.member,
                      });
                      setEditInitialValues(x);
                      setModalMode(2);
                    }}
                  >
                    ändern
                  </Button>
                  <Button
                    danger={true}
                    onClick={() => {
                      client.models.CalendarEvent.delete({
                        id: x.id.toString(),
                      });
                      setModalMode(0);
                    }}
                  >
                    Löschen
                  </Button>
                </div>
              </Col>
            ))}
        </Row>
      </Modal>

      {/* Neu Modal */}
      <Modal
        title="Neuer Termin"
        open={modalMode === 2}
        onCancel={() => setModalMode(0)}
        onOk={handleModalOk}
      >
        <Form form={form}>
          <Form.Item name="time" label="Uhrzeit von">
            <TimeInput />
          </Form.Item>
          <Form.Item name="timeTo" label="Uhrzeit bis">
            <TimeInput />
          </Form.Item>
          <Form.Item name="color" label="Farbe">
            <ColorPickerDropdown />
          </Form.Item>
          <Form.Item
            name="description"
            label="Beschreibung"
            rules={[
              { required: true, message: "Bitte eine Beschreibung eingeben" },
            ]}
          >
            <Input.TextArea />
          </Form.Item>
          <Form.Item name="type" hidden={true}>
            <Input />
          </Form.Item>
          <Form.Item name="id" hidden={true}>
            <Input />
          </Form.Item>
          <Form.Item name="member" hidden={true}>
            <Input />
          </Form.Item>
        </Form>
      </Modal>

      {/* ganztägig */}
      <Modal
        title="Neues ganztägiges Ereignis"
        open={isDayModalVisible}
        onCancel={() => setIsDayModalVisible(false)}
        onOk={handleModalOk}
      >
        <Row>
          {activeEvents
            .filter((f) => !!f.type && f.type.toString() === "wholeday")
            .map((x) => (
              <Col span={24} key={x.id}>
                {x.content}
                <Button
                  onClick={() => {
                    client.models.CalendarEvent.delete({
                      id: x.id.toString(),
                    });
                    setIsDayModalVisible(false);
                  }}
                >
                  Löschen
                </Button>
              </Col>
            ))}
        </Row>
        <br />
        <Form form={form} initialValues={{ type: "wholeday" }}>
          <Form.Item
            name="description"
            label="Beschreibung"
            rules={[
              { required: true, message: "Bitte eine Beschreibung eingeben" },
            ]}
          >
            <Input.TextArea />
          </Form.Item>
          <Form.Item name="type" hidden={true}>
            <Input />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default CalendarTable;

function renderCellEvent(
  onCellClick: (record: any, member: any) => { onClick: () => void },
  record: any,
  entries: any,
  member: string,
  schoolHolidays: any
) {
  return (
    <div
      onClick={() => onCellClick(record, member)}
      style={{
        cursor: "pointer",
        ...getSchoolHolidayBorder(record, schoolHolidays),
      }}
    >
      {entries && entries.length > 0 ? (
        entries.map((entry, index) => (
          <div
            key={index}
            style={{
              marginBottom: "4px",
              backgroundColor: !!entry.color ? entry.color : "transparent",
            }}
          >
            {showTime(entry.time)}
            {showTime(entry.timeTo, true)} {entry.content}
          </div>
        ))
      ) : (
        <div></div>
      )}
    </div>
  );
}

function getSchoolHolidayBorder(record, schoolHolidays?) {
  if (!schoolHolidays) return {};

  // find holiday
  const firstDay = schoolHolidays.find((f) => f.start === record.key) || null;
  const lastDay = schoolHolidays.find((f) => f.end === record.key) || null;
  const dayBetween = isInDaysBetween(record.key, schoolHolidays) || null;

  const ret =
    !!firstDay && !!lastDay
      ? {
          borderLeft: "2px solid red",
          borderTop: "2px solid red",
          borderBottom: "2px solid red",
          height: "25px",
          marginLeft: "-10px",
          marginRight: "-10px",
        }
      : !!lastDay
      ? {
          borderLeft: "2px solid red",
          borderBottom: "2px solid red",
          height: "25px",
          marginLeft: "-10px",
          marginRight: "-10px",
        }
      : !!dayBetween
      ? {
          borderLeft: "2px solid red",
          height: "25px",
          marginLeft: "-10px",
          marginRight: "-10px",
        }
      : !!firstDay
      ? {
          borderLeft: "2px solid red",
          borderTop: "2px solid red",
          height: "25px",
          marginLeft: "-10px",
          marginRight: "-10px",
        }
      : {};
  return ret;
}

function isInDaysBetween(day, schoolHolidays) {
  return schoolHolidays.find((f) =>
    moment(day).isBetween(moment(f.start), moment(f.end))
  );
}

function showTime(time, toTime = false) {
  if (time === undefined || !time) return "";
  const ret =
    time !== "00:00"
      ? time.length > 5
        ? moment(time).format("HH:mm")
        : moment(time, "HH:mm").format("HH:mm")
      : "";

  if (toTime) return `- ${ret}`;
  return ret;
}
