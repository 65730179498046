import React from "react";
import { Select } from "antd";

const { Option } = Select;

const ColorPickerDropdown = ({ value = "#ffffff", onChange }) => {
  const colors = [
    { name: "Weiß", hex: "#ffffff" },
    { name: "Grün", hex: "#99ff99" },
    { name: "Rot", hex: "#ff8888" },
    { name: "Blau", hex: "#9999ff" },
    { name: "Grau", hex: "#898989" },
    { name: "Gelb", hex: "#ffff99" },
    { name: "Orange", hex: "#ffa599" },
    { name: "Lila", hex: "#822282" },
    { name: "Braun", hex: "#b63b3b" },
    { name: "Pink", hex: "#ff69b4" },
  ];

  // Handle color change and call onChange to inform the parent component (Form.Item)
  const handleColorChange = (selectedColor) => {
    if (onChange) {
      onChange(selectedColor); // Inform the parent of the new value
    }
  };

  return (
    <Select
      value={value} // Controlled by the Form
      onChange={handleColorChange} // Trigger onChange when color is selected
      style={{
        width: 150,
        backgroundColor: value, // Use the selected color as background
        color: "#000000", // Always black text for readability
      }}
      dropdownStyle={{ color: "#000000" }} // Dropdown items with black text
    >
      {colors.map((color) => (
        <Option
          key={color.hex}
          value={color.hex}
          style={{ backgroundColor: color.hex, color: "#000000" }}
        >
          {color.name}
        </Option>
      ))}
    </Select>
  );
};

export default ColorPickerDropdown;
