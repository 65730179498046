import React, { useEffect, useState } from "react";
import { Tabs, Table, Input, Button, message } from "antd";
import { generateClient } from "aws-amplify/data";
import type { Schema } from "../../../amplify/data/resource";
import CalendarMenu from "./CalendarMenu.tsx";

const client = generateClient<Schema>();
const { TabPane } = Tabs;

// Zeitblöcke und Wochentage
const timeSlots = [
  "08:00 - 08:45",
  "08:45 - 09:30",
  "09:45 - 10:30",
  "10:30 - 11:15",
  "11:30 - 12:15",
  "12:15 - 13:00",
  "13:30 - 16:00",
];
const weekDays = ["Montag", "Dienstag", "Mittwoch", "Donnerstag", "Freitag"];

const SchoolTimeTable = () => {
  const [timeTable, setTimeTable] = useState([]);
  const [editingCell, setEditingCell] = useState({});
  const [loading, setLoading] = useState(false);

  // Subscription für die Daten aus AWS Amplify
  useEffect(() => {
    const subscription = client.models.TimeTable.observeQuery().subscribe({
      next: (data) => setTimeTable([...data.items]),
    });
    return () => subscription.unsubscribe();
  }, []);

  // Zelle bearbeiten
  const onCellEdit = (member, day, timeSlot) => {
    setEditingCell({ member, day, timeSlot });
  };

  // Änderungen speichern
  const saveChanges = async (member, cellId, day, content, id) => {
    setLoading(true);
    try {
      if (!!id) {
        await client.models.TimeTable.update({
          ...{ member, cellId, day, content },
          id,
        });
      } else {
        await client.models.TimeTable.create({ member, cellId, day, content });
      }
      message.success("Stundenplan erfolgreich aktualisiert!");
      setEditingCell({});
    } catch (error) {
      message.error("Fehler beim Speichern!");
      console.error(error);
    }
    setLoading(false);
  };

  // Struktur des Stundenplans
  const renderTable = (member) => {
    const columns = [
      {
        title: "Zeit",
        dataIndex: "time",
        key: "time",
        width: "100px",
        render: (text) => <strong>{text}</strong>,
      },
      ...weekDays.map((day, dayIndex) => ({
        title: day,
        dataIndex: day,
        render: (_, record) => {
          const content = timeTable.find(
            (entry) =>
              entry.member === member &&
              entry.day === dayIndex &&
              entry.cellId === record.cellId
          );

          const isEditing =
            editingCell.member === member &&
            editingCell.day === dayIndex &&
            editingCell.timeSlot === record.cellId;

          return isEditing ? (
            <Input
              defaultValue={content?.content}
              onPressEnter={(e) =>
                saveChanges(
                  member,
                  record.cellId,
                  dayIndex,
                  e.target.value,
                  content?.id || null
                )
              }
              onBlur={() => setEditingCell({})}
            />
          ) : (
            <div onClick={() => onCellEdit(member, dayIndex, record.cellId)}>
              {content?.content || "—"}
            </div>
          );
        },
      })),
    ];

    return (
      <Table
        columns={columns}
        dataSource={timeSlots.map((_, index) => ({
          key: index,
          cellId: index,
          time: timeSlots[index],
        }))}
        rowKey="cellId"
        pagination={false}
        loading={loading}
      />
    );
  };

  return (
    <div>
      <CalendarMenu />
      <Tabs defaultActiveKey="Noah">
        {["Noah", "Sonja", "Henry"].map((member) => (
          <TabPane tab={member} key={member}>
            {renderTable(member)}
          </TabPane>
        ))}
      </Tabs>
    </div>
  );
};

export default SchoolTimeTable;

// import React, { useState, useEffect, useRef } from "react";
// import { Modal, Input, Button, Table, Tabs } from "antd";
// import "antd/dist/reset.css"; // Resets default styles
// import CalendarMenu from "./CalendarMenu.tsx";
// import { generateClient } from "aws-amplify/data";
// import type { Schema } from "../../../amplify/data/resource";

// const client = generateClient<Schema>();

// const memberDef = ["Noah", "Sonja", "Henry"];
// const days = ["Montag", "Dienstag", "Mittwoch", "Donnerstag", "Freitag"];
// const periods = [
//   "08:00 - 08:45",
//   "08:45 - 09:30",
//   "09:45 - 10:30",
//   "10:30 - 11:15",
//   "11:30 - 12:15",
//   "12:15 - 13:00",
//   "13:30 - 16:00",
// ];

// const SchoolTimetable: React.FC = () => {
//   const [timeTable, setTimeTable] = useState<Schema["TimeTable"]["type"][]>([]);
//   const [schedule, setSchedule] = useState(
//     Array.from({ length: memberDef.length }, () =>
//       Array.from({ length: periods.length }, () =>
//         Array.from({ length: days.length }, () => "")
//       )
//     )
//   );
//   const [isModalOpen, setIsModalOpen] = useState(false);
//   const [selectedCell, setSelectedCell] = useState({
//     member: null as string | null,
//     row: null as number | null,
//     col: null as number | null,
//   });
//   const [subject, setSubject] = useState<string>("");
//   const [data, setData] = useState([]);
//   const [selectedMember, setSelectedMember] = useState<string>(memberDef[0]);
//   const firstInputRef = useRef(null);

//   useEffect(() => {
//     if (isModalOpen && !!firstInputRef) {
//       firstInputRef.current.focus(); // Setzt den Fokus auf das erste Eingabefeld
//     }
//   }, [isModalOpen]);

//   useEffect(() => {
//     const subscription = client.models.TimeTable.observeQuery().subscribe({
//       next: (data) => {
//         setTimeTable([...data.items]);
//       },
//     });
//     return () => subscription.unsubscribe();
//   }, []);

//   useEffect(() => {
//     const newData = periods.map((period, index) => {
//       const row: any = { key: index, period };
//       days.forEach((_, colIndex) => {
//         const findEntry =
//           timeTable.find(
//             (f) =>
//               f.day === colIndex &&
//               f.member === selectedMember &&
//               f.cellId === index
//           )?.content || null;
//         if (!!findEntry)
//           schedule[memberDef.indexOf(selectedMember)][index][colIndex] =
//             findEntry;
//         row[`day${colIndex}`] =
//           schedule[memberDef.indexOf(selectedMember)][index][colIndex];
//       });
//       return row;
//     });

//     // clear
//     // timeTable.forEach((tt) => {
//     //   client.models.TimeTable.delete({ id: tt.id });
//     // });

//     setData(newData);
//   }, [timeTable, selectedMember]);

//   const saveOrUpdateTimeTable = (values: {
//     member: string;
//     cellId: number;
//     day: number;
//     content: string;
//   }) => {
//     const foundEntryId =
//       timeTable.find(
//         (tt) =>
//           tt.member === selectedMember &&
//           tt.cellId === values.cellId &&
//           tt.day === values.day
//       )?.id || null;

//     if (foundEntryId) {
//       client.models.TimeTable.update({ ...values, id: foundEntryId });
//     } else {
//       client.models.TimeTable.create(values);
//     }
//   };

//   const handleCellClick = (row: number, col: number) => {
//     setSelectedCell({ member: selectedMember, row, col });
//     setSubject(schedule[memberDef.indexOf(selectedMember)][row][col]);
//     setIsModalOpen(true);
//   };

//   const handleSave = (member: string) => {
//     const newSchedule = [...schedule];
//     newSchedule[memberDef.indexOf(selectedMember)][selectedCell.row!][
//       selectedCell.col!
//     ] = subject;
//     setSchedule(newSchedule);
//     setIsModalOpen(false);
//     setSubject("");

//     saveOrUpdateTimeTable({
//       cellId: selectedCell.row!,
//       day: selectedCell.col!,
//       member,
//       content: subject,
//     });
//   };

//   const columns = [
//     { title: "Stunde", dataIndex: "period", key: "period", width: "80px" },
//     ...days.map((day, colIndex) => ({
//       title: day,
//       dataIndex: `day${colIndex}`,
//       key: day,
//       render: (_: any, __: any, rowIndex: number) => (
//         <div
//           style={{
//             padding: "8px",
//             border: "1px solid #ccc",
//             cursor: "pointer",
//             textAlign: "center",
//             backgroundColor: "#f5f5f5",
//             borderRadius: "4px",
//           }}
//           onClick={() => handleCellClick(rowIndex, colIndex)}
//         >
//           {schedule[memberDef.indexOf(selectedMember)][rowIndex][colIndex] ||
//             "Fach hinzufügen"}
//         </div>
//       ),
//     })),
//   ];

//   const onSelectNewMember = (key: string) => {
//     setSelectedMember(memberDef[parseInt(key) - 1]);
//   };

//   const renderTable = (member: string) => {
//     return (
//       <>
//         <Table
//           columns={columns}
//           dataSource={data}
//           pagination={false}
//           scroll={{ x: "100%" }}
//           bordered
//           size="middle"
//           style={{ fontSize: "14px" }}
//         />
//         <Modal
//           title="Schulfach eingeben"
//           open={isModalOpen}
//           onCancel={() => setIsModalOpen(false)}
//           footer={[
//             <Button key="cancel" onClick={() => setIsModalOpen(false)}>
//               Abbrechen
//             </Button>,
//             <Button
//               key="save"
//               type="primary"
//               onClick={() => handleSave(member)}
//             >
//               Speichern
//             </Button>,
//           ]}
//         >
//           <Input
//             value={subject}
//             ref={firstInputRef}
//             onChange={(e) => setSubject(e.target.value)}
//             placeholder="Schulfach"
//           />
//         </Modal>
//       </>
//     );
//   };

//   const items = memberDef.map((member, index) => ({
//     key: (index + 1).toString(),
//     label: member,
//     children: renderTable(member),
//   }));

//   return (
//     <div>
//       <CalendarMenu />
//       <Tabs items={items} onChange={onSelectNewMember} />
//     </div>
//   );
// };

// export default SchoolTimetable;
