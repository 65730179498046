import React, { useState, useEffect } from "react";
import { Input, Form } from "antd";

const TimeInput = ({ value = "00:00", onChange }) => {
  const [localHours, setLocalHours] = useState(value.split(":")[0]);
  const [localMinutes, setLocalMinutes] = useState(value.split(":")[1]);

  useEffect(() => {
    const [hours, minutes] = value.split(":");
    setLocalHours(hours);
    setLocalMinutes(minutes);
  }, [value]);

  // Validierung und Korrektur der Stunden (00-23)
  const validateHours = (input) => {
    let val = parseInt(input, 10);
    if (isNaN(val) || val < 0 || val > 23) {
      val = "00";
    } else if (val < 10) {
      val = `0${val}`;
    } else {
      val = `${val}`;
    }
    return val;
  };

  // Validierung und Korrektur der Minuten (00-59)
  const validateMinutes = (input) => {
    let val = parseInt(input, 10);
    if (isNaN(val) || val < 0 || val > 59) {
      val = "00";
    } else if (val < 10) {
      val = `0${val}`;
    } else {
      val = `${val}`;
    }
    return val;
  };

  const handleHoursChange = (e) => {
    const newHours = e.target.value;
    setLocalHours(newHours);
    if (newHours.length === 2) {
      const validatedHours = validateHours(newHours);
      setLocalHours(validatedHours);
      onChange(`${validatedHours}:${localMinutes}`);
    }
  };

  const handleMinutesChange = (e) => {
    const newMinutes = e.target.value;
    setLocalMinutes(newMinutes);
    if (newMinutes.length === 2) {
      const validatedMinutes = validateMinutes(newMinutes);
      setLocalMinutes(validatedMinutes);
      onChange(`${localHours}:${validatedMinutes}`);
    }
  };

  const handleHoursBlur = () => {
    const validatedHours = validateHours(localHours);
    setLocalHours(validatedHours);
    onChange(`${validatedHours}:${localMinutes}`);
  };

  const handleMinutesBlur = () => {
    const validatedMinutes = validateMinutes(localMinutes);
    setLocalMinutes(validatedMinutes);
    onChange(`${localHours}:${validatedMinutes}`);
  };

  return (
    <div style={{ display: "flex", gap: "8px" }}>
      <Input
        value={localHours}
        onChange={handleHoursChange}
        onBlur={handleHoursBlur}
        maxLength={2}
        style={{ width: "60px", textAlign: "center" }}
        placeholder="HH"
      />
      <span>:</span>
      <Input
        value={localMinutes}
        onChange={handleMinutesChange}
        onBlur={handleMinutesBlur}
        maxLength={2}
        style={{ width: "60px", textAlign: "center" }}
        placeholder="MM"
      />
    </div>
  );
};

export default TimeInput;
