import React, { useContext } from "react";
import { Dropdown, Modal } from "antd";
import { SquareDataContext } from "../contexts/SquareData";
import { LayerDataContext } from "../contexts/LayerData";

const DeleteElement = (props) => {
  const { id } = props;
  const { deleteSquares, squareData } = useContext(SquareDataContext);
  const { deleteLayer } = useContext(LayerDataContext);

  const contextItems = [{ label: "löschen", key: "1" }];

  const onClick = ({ key }) => {
    if (key === "1") deleteSquareConfirm(id);
  };

  const deleteSquareConfirm = (id) => {
    if (id.includes("tr")) {
      Modal.confirm({
        title: "Soll dieses Element gelöscht werden?",
        onOk: () => {
          deleteSquares([id]);
        },
      });
    } else if (id.includes("lr")) {
      Modal.confirm({
        title: "Soll diese Ebene mitsamt seinen Inhalten gelöscht werden?",
        onOk: () => {
          deleteLayer(id);
          deleteSquares(squareData.filter((x) => x.layer === id));
        },
      });
    }
  };

  return (
    <Dropdown menu={{ items: contextItems, onClick }} trigger={["contextMenu"]}>
      {props.children}
    </Dropdown>
  );
};

export default DeleteElement;
