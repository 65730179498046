import React, { createContext, useState } from "react";

const SquareDataContext = createContext([]);

function SquareDataProvider(props) {
  const [squareData, setSquareData] = useState([]);

  const deleteSquares = (ids) => {
    const newSquareData = squareData.filter((f) => !ids.includes(f.id));
    setSquareData(newSquareData);
  };

  return (
    <SquareDataContext.Provider
      value={{ squareData, setSquareData, deleteSquares }}
    >
      {props.children}
    </SquareDataContext.Provider>
  );
}

export { SquareDataContext, SquareDataProvider };
