import React, { createContext, useState, useEffect } from "react";

const LayerDataContext = createContext([]);

function LayerDataProvider(props) {
  const [layerData, setLayerData] = useState([]);
  const [currentPath, setCurrentPath] = useState([{ text: "Home", layer: "" }]);
  const [currentPathIndex, setCurrentPathIndex] = useState(0);

  const deleteLayer = (id) => {
    const newLayerData = layerData.filter((f) => f.id !== id);
    setLayerData(newLayerData);
  };

  return (
    <LayerDataContext.Provider
      value={{
        layerData,
        setLayerData,
        currentPath,
        setCurrentPath,
        currentPathIndex,
        setCurrentPathIndex,
        deleteLayer,
      }}
    >
      {props.children}
    </LayerDataContext.Provider>
  );
}

export { LayerDataContext, LayerDataProvider };
