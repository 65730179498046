import React, { useContext } from "react";
import { Button, Modal, Row, Col, Tooltip } from "antd";
import {
  FileAddOutlined,
  PlusSquareOutlined,
  SaveOutlined,
  DeleteOutlined,
} from "@ant-design/icons";

import { SquareDataContext } from "./contexts/SquareData";
import { LayerDataContext } from "./contexts/LayerData";
import LayerBreadcrumbs from "./LayerBreadcrumbs";

const MainMenu = () => {
  const { squareData, setSquareData } = useContext(SquareDataContext);
  const { layerData, setLayerData, currentPath, currentPathIndex } =
    useContext(LayerDataContext);

  const handleSave = () => {
    localStorage.setItem("squareDataSave", JSON.stringify(squareData));
    localStorage.setItem("layerDataSave", JSON.stringify(layerData));
  };

  const handleClear = () => {
    Modal.confirm({
      title: "Soll wirklich alles gelöscht werden?",
      onOk: () => {
        localStorage.clear();
        window.location.reload();
      },
    });
  };

  const handleAddSquare = () => {
    const newSquare = {
      id: `tr${squareData.length}`,
      text: "- Insert Text -",
      width: 200,
      height: 160,
      x: 20,
      y: 20,
      layer: currentPath[currentPathIndex].layer,
      title: "- Title -",
    };
    setSquareData([...squareData, newSquare]);
  };

  const handleAddLayer = () => {
    const newLayer = {
      id: `lr${layerData.length}`,
      text: "- Insert Text -",
      width: 100,
      height: 100,
      x: 20,
      y: 20,
      layer: currentPath[currentPathIndex].layer,
      color: `#${(((1 << 24) * Math.random()) | 0).toString(16)}`,
    };
    setLayerData([...layerData, newLayer]);
  };

  return (
    <Row gutter={[16, 16]}>
      <Col span={3}>
        <Tooltip title="Neue Ebene">
          <Button icon={<FileAddOutlined />} onClick={handleAddLayer} />
        </Tooltip>
        <Tooltip title="Textbox">
          <Button icon={<PlusSquareOutlined />} onClick={handleAddSquare} />
        </Tooltip>
      </Col>
      <Col span={3}>
        <Button icon={<SaveOutlined />} onClick={handleSave} />
        <Button icon={<DeleteOutlined />} danger={true} onClick={handleClear} />
      </Col>
      <Col span={12}>
        <LayerBreadcrumbs />
      </Col>
    </Row>
  );
};

export default MainMenu;
