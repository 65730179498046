import React, { useState, useContext } from "react";
import { Rnd } from "react-rnd";
import { Typography } from "antd";

import { LayerDataContext } from "../contexts/LayerData";
import DeleteElement from "../components/DeleteElement";

const { Text } = Typography;

const LayerRectangle = (props) => {
  const { data, saveNewDimensions } = props;
  const { currentPathIndex, setCurrentPathIndex, currentPath, setCurrentPath } =
    useContext(LayerDataContext);

  const width = "80px";
  const height = "80px";
  const [x, setX] = useState(data.x);
  const [y, setY] = useState(data.y);
  const [text, setText] = useState(data.text || "--");
  const [color, setColor] = useState(data.color || "#22bbff");

  const handleDoubleClick = () => {
    setCurrentPathIndex(currentPathIndex + 1);
    setCurrentPath(currentPath.concat({ text, layer: data.id }));
  };

  const handleChange = (value) => {
    setText(value);
    saveNewDimensions(data.id, { id: data.id, text: value, x, y, color });
  };

  return (
    <DeleteElement id={data.id}>
      <div onDoubleClick={handleDoubleClick}>
        <Rnd
          style={{
            padding: "10px",
            border: "solid 1px",
            background: color,
            wordWrap: "break-word",
          }}
          size={{ width, height }}
          position={{ x, y }}
          enableResizing={false}
          onDragStop={(e, d) => {
            setX(d.x);
            setY(d.y);
            saveNewDimensions(data.id, {
              id: data.id,
              text,
              x: d.x,
              y: d.y,
              color,
            });
          }}
          dragGrid={[20, 20]}
        >
          <Text
            editable={{
              onChange: handleChange,
              triggerType: ["text"],
            }}
            style={{ fontSize: "12px" }}
          >
            {text}
          </Text>
          {/* {isEditing ? (
          <textarea
            value={text}
            onChange={handleChange}
            onBlur={handleBlur}
            onKeyDown={handleKeyDown}
            rows={4}
            style={{
              width: "100%",
              height: `calc(${height} - 5px)`,
              resize: "none",
              fontSize: "10px",
            }}
            autoFocus
          />
        ) : (
          <div>
            <div>
              {text.split("\n").map((line, index) => (
                <div key={index} style={{ fontSize: "10px" }}>
                  {line}
                </div>
              ))}
            </div>
            <div
              style={{
                display: "flex",
                position: "absolute",
                top: "-20px",
                right: "0px",
                overflow: "visible",
              }}
            >
              <FolderOutlined
                onClick={() => {
                  setCurrentPathIndex(currentPathIndex + 1);
                  setCurrentPath(currentPath.concat(text));
                }}
              />
            </div>
          </div>
        )} */}
        </Rnd>
      </div>
    </DeleteElement>
  );
};

export default LayerRectangle;
