import React, { useContext, useEffect } from "react";
import { Breadcrumb, ConfigProvider } from "antd";
import { HomeOutlined } from "@ant-design/icons";

import { LayerDataContext } from "./contexts/LayerData";

const LayerBreadcrumbs = () => {
  const { currentPath, setCurrentPath, setCurrentPathIndex } =
    useContext(LayerDataContext);

  const navigateToPath = (path) => {
    setCurrentPathIndex(path.length - 1);
    setCurrentPath(path);
  };

  return (
    <ConfigProvider
      theme={{
        components: {
          Breadcrumb: { separatorColor: "white" },
        },
      }}
    >
      <Breadcrumb>
        {currentPath.map((cp, index) => (
          <Breadcrumb.Item
            key={index}
            onClick={() => navigateToPath(currentPath.slice(0, index + 1))}
          >
            <div style={{ color: "white" }}>{cp.text}</div>
          </Breadcrumb.Item>
        ))}
      </Breadcrumb>
    </ConfigProvider>
  );
};

export default LayerBreadcrumbs;
