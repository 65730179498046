import React from "react";
import "../App.css";
import { Amplify } from "aws-amplify";
import outputs from "../amplify_outputs.json";
import CalendarTable from "./components/CalendarTable.tsx";

Amplify.configure(outputs);

export default () => {
  return <CalendarTable />;
};
