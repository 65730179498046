import React from "react";
import "../App.css";

import MainMenu from "./MainMenu";
import RectangleManager from "./manager/RectangleManager";
import LayerManager from "./manager/LayerManager";
import { SquareDataProvider } from "./contexts/SquareData";
import { LayerDataProvider } from "./contexts/LayerData";

const IndexNoteTool = () => {
  return (
    <div className="dot-grid">
      <LayerDataProvider>
        <SquareDataProvider>
          <MainMenu />
          <RectangleManager />
          <LayerManager />
        </SquareDataProvider>
      </LayerDataProvider>
    </div>
  );
};

export default IndexNoteTool;
