import React, { useState } from "react";
import { Menu, Button } from "antd";
import { CalendarOutlined, TableOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { MenuFoldOutlined, MenuUnfoldOutlined } from "@ant-design/icons";

const { Item } = Menu;

const CalendarMenu = () => {
  const [current, setCurrent] = useState("calendar");
  const navigate = useNavigate(); // Falls du React Router für die Navigation verwendest
  const [collapsed, setCollapsed] = useState(true);
  const toggleCollapsed = () => {
    setCollapsed(!collapsed);
  };

  const handleClick = (e) => {
    setCurrent(e.key);
    // Navigation basierend auf dem ausgewählten Menüeintrag
    if (e.key === "calendar") {
      navigate("/"); // Navigiere zur Kalender-Seite
    } else if (e.key === "timetable") {
      navigate("/timetable"); // Navigiere zur Stundenplan-Seite
    }
  };

  return (
    <>
      <Button
        type="primary"
        onClick={toggleCollapsed}
        style={{
          marginBottom: 16,
        }}
      >
        {collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
      </Button>
      {!collapsed ? (
        <Menu
          onClick={handleClick}
          selectedKeys={[current]}
          mode="inline"
          theme="light"
          inlineCollapsed={collapsed}
        >
          <Item key="calendar" icon={<CalendarOutlined />}>
            Kalender
          </Item>
          <Item key="timetable" icon={<TableOutlined />}>
            Stundenpläne
          </Item>
        </Menu>
      ) : (
        <></>
      )}
    </>
  );
};

export default CalendarMenu;
