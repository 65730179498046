import React, { useContext, useEffect } from "react";

import LayerRectangle from "../elements/LayerRectangle";
import { LayerDataContext } from "../contexts/LayerData";

const LayerManager = () => {
  const { layerData, setLayerData, currentPathIndex, currentPath } =
    useContext(LayerDataContext);

  useEffect(() => {
    const savedLayers = localStorage.getItem("layerDataSave");
    if (savedLayers) {
      setLayerData(JSON.parse(savedLayers));
    }
  }, []);

  const saveNewDimensions = (idToSearch, newData) => {
    const newLayerData = layerData.map((x) => {
      if (x.id === idToSearch) {
        return { ...x, x: newData.x, y: newData.y, text: newData.text };
      }
      return x;
    });
    setLayerData(newLayerData);
  };

  return (
    <div className="square-container">
      {layerData
        .filter((sq) => sq.layer === currentPath[currentPathIndex].layer)
        .map((sdat) => (
          <LayerRectangle
            key={sdat.id}
            data={sdat}
            saveNewDimensions={saveNewDimensions}
          />
        ))}
    </div>
  );
};

export default LayerManager;
